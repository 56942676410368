import React from "react";
import {cloneDeep} from "lodash";
import './VideoSlider.sass';

import {Carousel, CarouselControl, CarouselItem,} from 'reactstrap';
import Authentication from "../authentication/Authentication";
import ModalPopup from "../popup/ModalPopup";
import {Constant} from "../constant/Constant";
import SliderDownload from "./SliderDownload";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import {StringUtil} from "../util/StringUtil";
import { connect } from "react-redux";

interface IProps {
    programId?: string,
    slideItems: Attribute[],
    timeIndex?: any,
    onSwitchSlideManual?: any,
    manualMode: boolean,
    viewPermission?: boolean,
    documentUrls?: any,
    addNewMemo?: any,
    deleteMemo?: any,
    isContentFree: boolean,
    isHideNumberFavorite: boolean,
}

interface Slider {
    doc: string,
    index_time: number,
    prg_per_id: string,
    index_title: string,
    url: string,
    active?: boolean,
    isFavorite: boolean,
    index_type: number,
    favorite_num: number,
    translatedImg?: string
}

interface Attribute {
    index: number;
    attribute: Slider;
}

interface IState {
    animating: boolean,
    activeIndex: number,
    autoSlide: boolean,
    slidesList: any,
    isShowDeleteMemoPopup: boolean | false,
    favoriteSlideHover: number,
    slidesListIndex: number,
    languageTranslate: string,
    isTranslatingImg: boolean,
}

const languages = [
    {
        language: "en",
        name: "English"
    },
    {
        language: "ja",
        name: "Japanese"
    },
    {
        language: "zh",
        name: "Chinese (Simplified)"
    },
    {
        language: "zh-TW",
        name: "Chinese (Traditional)"
    },
    {
        language: "es",
        name: "Spanish"
    },
    {
        language: "ar",
        name: "Arabic"
    },
    {
        language: "fr",
        name: "French"
    },
    {
        language: "hi",
        name: "Hindi"
    },
    {
        language: "id",
        name: "Indonesian"
    },
    {
        language: "ms",
        name: "Malay"
    },
    {
        language: "ms-Arab",
        name: "Malay (Jawi)"
    },
    {
        language: "pt",
        name: "Portuguese"
    },
    {
        language: "pt-PT",
        name: "Portuguese (Portugal)"
    },
    {
        language: "bn",
        name: "Bengali"
    },
    {
        language: "ru",
        name: "Russian"
    },
    {
        language: "de",
        name: "German"
    },
    {
        language: "pa",
        name: "Punjabi"
    },
    {
        language: "jw",
        name: "Javanese"
    },
    {
        language: "ko",
        name: "Korean"
    },
    {
        language: "ta",
        name: "Tamil"
    },
    {
        language: "vi",
        name: "Vietnamese"
    },
    {
        language: "te",
        name: "Telugu"
    },
    {
        language: "mr",
        name: "Marathi"
    },
    {
        language: "ur",
        name: "Urdu"
    },
    {
        language: "it",
        name: "Italian"
    },
    {
        language: "tr",
        name: "Turkish"
    },
    {
        language: "ab",
        name: "Abkhaz"
    },
    {
        language: "ace",
        name: "Acehnese"
    },
    {
        language: "ach",
        name: "Acholi"
    },
    {
        language: "aa",
        name: "Afar"
    },
    {
        language: "af",
        name: "Afrikaans"
    },
    {
        language: "sq",
        name: "Albanian"
    },
    {
        language: "alz",
        name: "Alur"
    },
    {
        language: "am",
        name: "Amharic"
    },
    {
        language: "hy",
        name: "Armenian"
    },
    {
        language: "as",
        name: "Assamese"
    },
    {
        language: "av",
        name: "Avar"
    },
    {
        language: "awa",
        name: "Awadhi"
    },
    {
        language: "ay",
        name: "Aymara"
    },
    {
        language: "az",
        name: "Azerbaijani"
    },
    {
        language: "ban",
        name: "Balinese"
    },
    {
        language: "bal",
        name: "Baluchi"
    },
    {
        language: "bm",
        name: "Bambara"
    },
    {
        language: "bci",
        name: "Baoulé"
    },
    {
        language: "ba",
        name: "Bashkir"
    },
    {
        language: "eu",
        name: "Basque"
    },
    {
        language: "btx",
        name: "Batak Karo"
    },
    {
        language: "bts",
        name: "Batak Simalungun"
    },
    {
        language: "bbc",
        name: "Batak Toba"
    },
    {
        language: "be",
        name: "Belarusian"
    },
    {
        language: "bem",
        name: "Bemba"
    },
    {
        language: "bew",
        name: "Betawi"
    },
    {
        language: "bho",
        name: "Bhojpuri"
    },
    {
        language: "bik",
        name: "Bikol"
    },
    {
        language: "bs",
        name: "Bosnian"
    },
    {
        language: "br",
        name: "Breton"
    },
    {
        language: "bg",
        name: "Bulgarian"
    },
    {
        language: "bua",
        name: "Buryat"
    },
    {
        language: "yue",
        name: "Cantonese"
    },
    {
        language: "ca",
        name: "Catalan"
    },
    {
        language: "ceb",
        name: "Cebuano"
    },
    {
        language: "ch",
        name: "Chamorro"
    },
    {
        language: "ce",
        name: "Chechen"
    },
    {
        language: "ny",
        name: "Chichewa"
    },
    {
        language: "chk",
        name: "Chuukese"
    },
    {
        language: "cv",
        name: "Chuvash"
    },
    {
        language: "co",
        name: "Corsican"
    },
    {
        language: "crh",
        name: "Crimean Tatar"
    },
    {
        language: "hr",
        name: "Croatian"
    },
    {
        language: "cs",
        name: "Czech"
    },
    {
        language: "da",
        name: "Danish"
    },
    {
        language: "fa-AF",
        name: "Dari"
    },
    {
        language: "din",
        name: "Dinka"
    },
    {
        language: "dv",
        name: "Divehi"
    },
    {
        language: "doi",
        name: "Dogri"
    },
    {
        language: "dov",
        name: "Dombe"
    },
    {
        language: "nl",
        name: "Dutch"
    },
    {
        language: "dyu",
        name: "Dyula"
    },
    {
        language: "dz",
        name: "Dzongkha"
    },
    {
        language: "eo",
        name: "Esperanto"
    },
    {
        language: "et",
        name: "Estonian"
    },
    {
        language: "ee",
        name: "Ewe"
    },
    {
        language: "fo",
        name: "Faroese"
    },
    {
        language: "fj",
        name: "Fijian"
    },
    {
        language: "tl",
        name: "Filipino"
    },
    {
        language: "fi",
        name: "Finnish"
    },
    {
        language: "fon",
        name: "Fon"
    },
    {
        language: "fy",
        name: "Frisian"
    },
    {
        language: "fur",
        name: "Friulian"
    },
    {
        language: "ff",
        name: "Fulfulde"
    },
    {
        language: "gaa",
        name: "Ga"
    },
    {
        language: "gl",
        name: "Galician"
    },
    {
        language: "lg",
        name: "Ganda"
    },
    {
        language: "ka",
        name: "Georgian"
    },
    {
        language: "el",
        name: "Greek"
    },
    {
        language: "gn",
        name: "Guarani"
    },
    {
        language: "gu",
        name: "Gujarati"
    },
    {
        language: "ht",
        name: "Haitian Creole"
    },
    {
        language: "cnh",
        name: "Hakha Chin"
    },
    {
        language: "ha",
        name: "Hausa"
    },
    {
        language: "haw",
        name: "Hawaiian"
    },
    {
        language: "iw",
        name: "Hebrew"
    },
    {
        language: "hil",
        name: "Hiligaynon"
    },
    {
        language: "hmn",
        name: "Hmong"
    },
    {
        language: "hu",
        name: "Hungarian"
    },
    {
        language: "hrx",
        name: "Hunsrik"
    },
    {
        language: "iba",
        name: "Iban"
    },
    {
        language: "is",
        name: "Icelandic"
    },
    {
        language: "ig",
        name: "Igbo"
    },
    {
        language: "ilo",
        name: "Iloko"
    },
    {
        language: "ga",
        name: "Irish Gaelic"
    },
    {
        language: "jam",
        name: "Jamaican Patois"
    },
    {
        language: "kac",
        name: "Jingpo"
    },
    {
        language: "kl",
        name: "Kalaallisut"
    },
    {
        language: "kn",
        name: "Kannada"
    },
    {
        language: "kr",
        name: "Kanuri"
    },
    {
        language: "pam",
        name: "Kapampangan"
    },
    {
        language: "kk",
        name: "Kazakh"
    },
    {
        language: "kha",
        name: "Khasi"
    },
    {
        language: "km",
        name: "Khmer"
    },
    {
        language: "cgg",
        name: "Kiga"
    },
    {
        language: "kg",
        name: "Kikongo"
    },
    {
        language: "rw",
        name: "Kinyarwanda"
    },
    {
        language: "ktu",
        name: "Kituba"
    },
    {
        language: "trp",
        name: "Kokborok"
    },
    {
        language: "kv",
        name: "Komi"
    },
    {
        language: "gom",
        name: "Konkani"
    },
    {
        language: "kri",
        name: "Krio"
    },
    {
        language: "ku",
        name: "Kurdish (Kurmanji)"
    },
    {
        language: "ckb",
        name: "Kurdish (Sorani)"
    },
    {
        language: "ky",
        name: "Kyrgyz"
    },
    {
        language: "lo",
        name: "Lao"
    },
    {
        language: "ltg",
        name: "Latgalian"
    },
    {
        language: "la",
        name: "Latin"
    },
    {
        language: "lv",
        name: "Latvian"
    },
    {
        language: "lij",
        name: "Ligurian"
    },
    {
        language: "li",
        name: "Limburgan"
    },
    {
        language: "ln",
        name: "Lingala"
    },
    {
        language: "lt",
        name: "Lithuanian"
    },
    {
        language: "lmo",
        name: "Lombard"
    },
    {
        language: "luo",
        name: "Luo"
    },
    {
        language: "lb",
        name: "Luxembourgish"
    },
    {
        language: "mk",
        name: "Macedonian"
    },
    {
        language: "mad",
        name: "Madurese"
    },
    {
        language: "mai",
        name: "Maithili"
    },
    {
        language: "mak",
        name: "Makassar"
    },
    {
        language: "mg",
        name: "Malagasy"
    },
    {
        language: "ml",
        name: "Malayalam"
    },
    {
        language: "mt",
        name: "Maltese"
    },
    {
        language: "mam",
        name: "Mam"
    },
    {
        language: "gv",
        name: "Manx"
    },
    {
        language: "mi",
        name: "Maori"
    },
    {
        language: "mh",
        name: "Marshallese"
    },
    {
        language: "mwr",
        name: "Marwadi"
    },
    {
        language: "mfe",
        name: "Mauritian Creole"
    },
    {
        language: "chm",
        name: "Meadow Mari"
    },
    {
        language: "mni-Mtei",
        name: "Meiteilon (Manipuri)"
    },
    {
        language: "min",
        name: "Minang"
    },
    {
        language: "lus",
        name: "Mizo"
    },
    {
        language: "mn",
        name: "Mongolian"
    },
    {
        language: "my",
        name: "Myanmar (Burmese)"
    },
    {
        language: "nhe",
        name: "Nahuatl (Eastern Huasteca)"
    },
    {
        language: "ndc-ZW",
        name: "Ndau"
    },
    {
        language: "nr",
        name: "Ndebele (South)"
    },
    {
        language: "new",
        name: "Nepalbhasa (Newari)"
    },
    {
        language: "ne",
        name: "Nepali"
    },
    {
        language: "bm-Nkoo",
        name: "NKo"
    },
    {
        language: "nso",
        name: "Northern Sotho"
    },
    {
        language: "no",
        name: "Norwegian"
    },
    {
        language: "nus",
        name: "Nuer"
    },
    {
        language: "oc",
        name: "Occitan"
    },
    {
        language: "or",
        name: "Odia (Oriya)"
    },
    {
        language: "om",
        name: "Oromo"
    },
    {
        language: "os",
        name: "Ossetian"
    },
    {
        language: "pag",
        name: "Pangasinan"
    },
    {
        language: "pap",
        name: "Papiamento"
    },
    {
        language: "ps",
        name: "Pashto"
    },
    {
        language: "fa",
        name: "Persian"
    },
    {
        language: "pl",
        name: "Polish"
    },
    {
        language: "pa-Arab",
        name: "Punjabi (Shahmukhi)"
    },
    {
        language: "kek",
        name: "Q'eqchi'"
    },
    {
        language: "qu",
        name: "Quechua"
    },
    {
        language: "rom",
        name: "Romani"
    },
    {
        language: "ro",
        name: "Romanian"
    },
    {
        language: "rn",
        name: "Rundi"
    },
    {
        language: "se",
        name: "Sami (North)"
    },
    {
        language: "sm",
        name: "Samoan"
    },
    {
        language: "sg",
        name: "Sango"
    },
    {
        language: "sa",
        name: "Sanskrit"
    },
    {
        language: "sat-Latn",
        name: "Santali"
    },
    {
        language: "gd",
        name: "Scots Gaelic"
    },
    {
        language: "sr",
        name: "Serbian"
    },
    {
        language: "st",
        name: "Sesotho"
    },
    {
        language: "crs",
        name: "Seychellois Creole"
    },
    {
        language: "shn",
        name: "Shan"
    },
    {
        language: "sn",
        name: "Shona"
    },
    {
        language: "scn",
        name: "Sicilian"
    },
    {
        language: "szl",
        name: "Silesian"
    },
    {
        language: "sd",
        name: "Sindhi"
    },
    {
        language: "si",
        name: "Sinhala"
    },
    {
        language: "sk",
        name: "Slovak"
    },
    {
        language: "sl",
        name: "Slovenian"
    },
    {
        language: "so",
        name: "Somali"
    },
    {
        language: "su",
        name: "Sundanese"
    },
    {
        language: "sus",
        name: "Susu"
    },
    {
        language: "sw",
        name: "Swahili"
    },
    {
        language: "ss",
        name: "Swati"
    },
    {
        language: "sv",
        name: "Swedish"
    },
    {
        language: "ty",
        name: "Tahitian"
    },
    {
        language: "tg",
        name: "Tajik"
    },
    {
        language: "ber-Latn",
        name: "Tamazight"
    },
    {
        language: "ber",
        name: "Tamazight (Tifinagh)"
    },
    {
        language: "tt",
        name: "Tatar"
    },
    {
        language: "tet",
        name: "Tetum"
    },
    {
        language: "th",
        name: "Thai"
    },
    {
        language: "bo",
        name: "Tibetan"
    },
    {
        language: "ti",
        name: "Tigrinya"
    },
    {
        language: "tiv",
        name: "Tiv"
    },
    {
        language: "tpi",
        name: "Tok Pisin"
    },
    {
        language: "to",
        name: "Tongan"
    },
    {
        language: "ts",
        name: "Tsonga"
    },
    {
        language: "tn",
        name: "Tswana"
    },
    {
        language: "tcy",
        name: "Tulu"
    },
    {
        language: "tum",
        name: "Tumbuka"
    },
    {
        language: "tk",
        name: "Turkmen"
    },
    {
        language: "tyv",
        name: "Tuvan"
    },
    {
        language: "ak",
        name: "Twi"
    },
    {
        language: "udm",
        name: "Udmurt"
    },
    {
        language: "uk",
        name: "Ukrainian"
    },
    {
        language: "ug",
        name: "Uyghur"
    },
    {
        language: "uz",
        name: "Uzbek"
    },
    {
        language: "ve",
        name: "Venda"
    },
    {
        language: "vec",
        name: "Venetian"
    },
    {
        language: "war",
        name: "Waray"
    },
    {
        language: "cy",
        name: "Welsh"
    },
    {
        language: "wo",
        name: "Wolof"
    },
    {
        language: "xh",
        name: "Xhosa"
    },
    {
        language: "sah",
        name: "Yakut"
    },
    {
        language: "yi",
        name: "Yiddish"
    },
    {
        language: "yo",
        name: "Yoruba"
    },
    {
        language: "yua",
        name: "Yucatec Maya"
    },
    {
        language: "zap",
        name: "Zapotec"
    },
    {
        language: "zu",
        name: "Zulu"
    },
    {
        language: "he",
        name: "Hebrew"
    },
    {
        language: "jv",
        name: "Javanese"
    },
    {
        language: "zh-CN",
        name: "Chinese (Simplified)"
    }
];

class VideoSlider extends React.Component<IProps, IState> {
    slideItemActive: HTMLImageElement | undefined;

    constructor(props: IProps) {
        super(props);
        this.state = {
            animating: false,
            activeIndex: 0,
            autoSlide: !this.props.manualMode,
            slidesList: this.props.slideItems,
            isShowDeleteMemoPopup: false,
            favoriteSlideHover: -1,
            slidesListIndex: -1,
            languageTranslate: "en",
            isTranslatingImg: false,
        };
    }

    setActiveIndex = (index) => {
        this.setState(prevState => ({
            activeIndex: index,
            animating: prevState.animating
        }))
    };

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.props.slideItems.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setActiveIndex(nextIndex);
        this.props.onSwitchSlideManual();
        this.setState({autoSlide: false})
    };

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.slideItems.length - 1 : this.state.activeIndex - 1;
        this.setActiveIndex(nextIndex);
        this.setState({autoSlide: false})
        this.props.onSwitchSlideManual();
    };
    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setActiveIndex(newIndex);
        this.props.onSwitchSlideManual();
    };

    setAnimating = (animating) => {
        this.setState(prevState => ({
            activeIndex: prevState.activeIndex,
            animating: animating
        }))
    };

    convertTimeToIndex = (timeIndex) => {
        let activeIndex = 0;
        let min = Number.MAX_VALUE;
        this.props.slideItems?.forEach((item, index) => {
            let sub = (timeIndex - item.attribute.index_time);
            if (sub >= 0 && sub <= min && item.attribute.index_type !== 2) {
                min = sub;
                activeIndex = index;
            }
        });
        return activeIndex;
    };
    handleAutoSlide = () => {
        let autoSlideMode = true
        this.setState({autoSlide: autoSlideMode});
        let targetIndex = this.convertTimeToIndex(this.props.timeIndex);
        this.goToIndex(targetIndex);
    }

    handleClickOnFavoriteIcon = (slidesListIndex?: number) => {
        let tmpSlidesList = cloneDeep(this.state.slidesList);
        let itemUpdate = tmpSlidesList[(slidesListIndex != undefined) ? slidesListIndex : this.state.activeIndex];
        if (itemUpdate.attribute.isFavorite) {
            this.setState({
                isShowDeleteMemoPopup: true
            })
            if (slidesListIndex != undefined) {
                this.setState({
                    slidesListIndex: slidesListIndex,
                })
            }
        } else {
            itemUpdate.attribute.isFavorite = true;
            if (itemUpdate.attribute.index_type === 1) {
                let activeIndex = 0;
                let min = Number.MAX_VALUE;
                tmpSlidesList.forEach((item,index) => {
                    let sub =  (this.props.timeIndex - item.attribute.index_time);
                    if(sub >= 0 && sub <= min && item.attribute.index_type !== 1){
                        min = sub;
                        activeIndex = index;
                    }
                });
                let itemUpdateParent = tmpSlidesList[activeIndex];
                itemUpdate.attribute.index_title = itemUpdateParent.attribute.index_title;
            }
            this.props.addNewMemo(cloneDeep(itemUpdate.attribute));
            itemUpdate.attribute.favorite_num ++;
        }
        this.setState({
            slidesList: tmpSlidesList
        })
    }

    onConfirmDeleteFavoriteMemo = () => {
        let tmpSlidesList = cloneDeep(this.state.slidesList);
        let itemUpdate = tmpSlidesList[(this.state.slidesListIndex != undefined && this.state.slidesListIndex !== -1) ? this.state.slidesListIndex : this.state.activeIndex];
        if (itemUpdate) {
            itemUpdate.attribute.isFavorite = false;
            this.props.deleteMemo(cloneDeep(itemUpdate.attribute));
            this.setState({
                slidesList: tmpSlidesList,
                isShowDeleteMemoPopup: false
            })
            itemUpdate.attribute.favorite_num --;
            this.setState({
                slidesListIndex: -1,
            })
        }
    }

    renderImageUrl = (doc) => {
        return `https://aaa.bbt757.com/asp/data/${this.props.programId}/ldoc/${doc}`;
    };

    onMouseEnterTimeline = (timeIndex) => {
        let index = this.convertTimeToIndex(timeIndex);
        let imageUrl = this.renderImageUrl(this.state.slidesList[index].attribute.doc);
        let slideImage = document.querySelector(".carousel .slide-image-wrapper.carousel-item.active .slide-image") as HTMLImageElement;
        slideImage.dataset.src = slideImage.src;
        slideImage.src = imageUrl;
        this.slideItemActive = slideImage;
        this.setState({
            favoriteSlideHover: index
        });
    }

    onMouseLeaveTimeline = () => {
        if (this.slideItemActive) {
            this.slideItemActive.src = this.slideItemActive.dataset.src as string;
        }
        this.setState({
            favoriteSlideHover: -1
        });
    }

    setContentFavoriteSlideEle = (index) => {
        let isAuthentication = new Authentication().checkCookieHaveUsernameAndToken();
        return <>
            <i className={`bi bi-bookmark-fill ${this.state.slidesList[index].attribute.isFavorite ? "favorite-icon" : "not-favorite-icon"}`}
               hidden={!isAuthentication}
               onClick={() => this.handleClickOnFavoriteIcon(undefined)}
            />
            {
                (this.state.slidesList[index].attribute.favorite_num >= 0 && !this.props.isHideNumberFavorite) &&
                <Tooltip title={`${this.state.slidesList[index].attribute.favorite_num}人がこのスライドをブックマークしています`}
                placement="left">
                    <span className="favorite-num" onClick={() => this.handleClickOnFavoriteIcon(undefined)}>
                        <span>{`${this.state.slidesList[index].attribute.favorite_num < 100 ?
                            this.state.slidesList[index].attribute.favorite_num : '99+'}`}</span>
                    </span>
                </Tooltip>
            }
        </>
    }

    updateFavoriteNum = () => {
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetFavoriteSlide?u=${u}&a=${a}&prg_id=${this.props.programId}`)
            .then(res => {
                const listFavoriteNum = res.data?.items;
                let favoriteNumMap = new Map(listFavoriteNum.map(item => [item.index_time, item.favorite_num]));
                let slidesList = this.state.slidesList;
                if (slidesList != undefined && favoriteNumMap != undefined) {
                    slidesList = slidesList.map(item => {
                        if (favoriteNumMap.has(item.attribute.index_time)) {
                            item.attribute.favorite_num = favoriteNumMap.get(item.attribute.index_time);
                        } else {
                            item.attribute.favorite_num = 0;
                        }
                        return item;
                    });
                    this.setState({
                        slidesList: slidesList
                    });
                }
            }).catch(err => {
        })
    }

    translateSlide = () => {
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        const imageUri = this.renderImageUrl(this.state.slidesList[this.state.activeIndex].attribute.doc);
        this.setState({
            isTranslatingImg: true
        });
        const data = {
            "languageTranslate": this.state.languageTranslate,
            "imageUri": imageUri
        }
        axios.post(
            process.env.REACT_APP_API_ENDPOINT + `/TranslateSlide`, data,
            {
                responseType: "blob", // Nhận dữ liệu dưới dạng Blob
            })
            .then(response => {
                const imageUrl = URL.createObjectURL(response.data);
                let tmpSlidesList = cloneDeep(this.state.slidesList);
                tmpSlidesList[this.state.activeIndex].attribute.translatedImg = imageUrl;
                this.setState({
                    slidesList: tmpSlidesList
                });
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.setState({
                    isTranslatingImg: false
                });
            })
    }

    handleChangeLanguagesTranslate = (event) => {
        let {value} = event.target;
        this.setState({
            languageTranslate: value
        })
    }

    handleClickRemoveTranslatedImg = () => {
        let tmpSlidesList = cloneDeep(this.state.slidesList);
        tmpSlidesList[this.state.activeIndex].attribute.translatedImg = undefined;
        this.setState({
            slidesList: tmpSlidesList
        });
    }

    render() {

        let isAuthentication = new Authentication().checkCookieHaveUsernameAndToken();
        {
            if (this.props.slideItems !== undefined && this.props.slideItems.length > 0) {
                this.props.slideItems.map((item, index) => {
                    item.index = index
                });
                return (
                    <div className="position-relative">
                        <Carousel
                            className={`${(this.props.isContentFree || !isAuthentication) ? 'slide-free' : ''}`}
                            activeIndex={this.state.activeIndex}
                            interval={false}
                            autoPlay={false}
                            next={this.next}
                            previous={this.previous}
                            keyboard={false}
                            slide={this.state.slidesList[this.state.activeIndex].attribute.index_type !== 1}>
                            {
                                this.state.slidesList?.map((item, index) => {
                                    return (
                                        <CarouselItem
                                            onExiting={() => this.setAnimating(true)}
                                            onExited={() => this.setAnimating(false)}
                                            className={"slide-image-wrapper"}
                                            key={item.index}>
                                            <img src={item.attribute.translatedImg ? item.attribute.translatedImg : this.renderImageUrl(item.attribute.doc)}
                                                 alt={item.attribute.index_title}
                                                 className={"d-block slide-image " + (this.props.isContentFree || (isAuthentication && this.props.viewPermission) ? "" : " filterBlur2")}/>
                                        </CarouselItem>
                                    );
                                })
                            }
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
                        </Carousel>
                        <span className="favorite-slide">
                            {
                                this.state.favoriteSlideHover >= 0 ? this.setContentFavoriteSlideEle(this.state.favoriteSlideHover) :
                                    this.setContentFavoriteSlideEle(this.state.activeIndex)
                            }
                        </span>
                        {
                            this.state.slidesList[this.state.activeIndex].attribute.translatedImg &&
                            <span className="remove-translated-img">
                                <Tooltip
                                    placement="top"
                                    title="翻訳した資料を閉じる">
                                    <button className="btn-icon" onClick={this.handleClickRemoveTranslatedImg}>
                                        <i className="bi bi-x"/>
                                    </button>
                                </Tooltip>
                            </span>
                        }
                        <div className="d-flex justify-content-between video-slide-control">
                            <div className="d-flex translate-slide">
                                {
                                    isAuthentication &&
                                    <>
                                        <Tooltip
                                            placement="top"
                                            title="翻訳">
                                            <button onClick={this.translateSlide} disabled={this.state.isTranslatingImg}
                                                    className="btn-icon">
                                                <img src="/g_translate.svg" alt="g_translate"/>
                                            </button>
                                        </Tooltip>
                                        <select className="form-control" style={{width: 130}}
                                                value={this.state.languageTranslate}
                                                onChange={this.handleChangeLanguagesTranslate}>
                                        {
                                                languages.map((language, index) => {
                                                    return <option value={language.language}
                                                                   key={index}>{language.name}</option>
                                                })
                                            }
                                        </select>
                                    </>
                                }
                            </div>
                            <div className="d-flex auto-download-slide">
                                {
                                    isAuthentication && <SliderDownload items={this.props.documentUrls || []}/>
                                }
                                {
                                    (!this.state.autoSlide) ?
                                        <Tooltip
                                            placement="top"
                                            title="自動スクロール再生">
                                            <button className="btn-icon"
                                                    onClick={this.handleAutoSlide}><i className="bi bi-play"/>
                                            </button>
                                        </Tooltip>
                                        : <div hidden={true}></div>
                                }
                            </div>
                        </div>
                        <ModalPopup
                            isShow={this.state.isShowDeleteMemoPopup}
                            title={"スライドのお気に入りを削除"}
                            message={"このスライドのお気に入りを削除しますか？"}
                            positiveButton={Constant.DELETE_MEMO_CONFIRM_BUTTON_TEXT}
                            negativeButton={Constant.CANCEL_BUTTON_TEXT}
                            onPositiveButtonClicked={this.onConfirmDeleteFavoriteMemo}
                            onNegativeButtonClicked={() => {
                                this.setState({isShowDeleteMemoPopup: false})
                            }}
                        />
                    </div>
                );
            } else {
                return <div/>;
            }
        }
    };
    checkRatioNinePerSixTeen (imageRatio) {
        if(imageRatio >= 0.5 && imageRatio <= 0.6){
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.timeIndex !== undefined) {
            // let prevTimeIncex = this.convertTimeToIndex(prevProps.timeIndex);
            let targetIndex = this.convertTimeToIndex(this.props.timeIndex);
            if (targetIndex != prevState.activeIndex && !this.props.manualMode && this.state.autoSlide) {
                this.goToIndex(targetIndex);
            }
        }
    }
    componentDidMount() {
        this.updateFavoriteNum();
    }
}

const mapStateToProps = (state) => ({
    isHideNumberFavorite: state.setting.isHideNumberFavorite
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(VideoSlider);
