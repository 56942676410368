import React from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip";

interface IProps {
  items: any,
}

interface IState {
  anchorEl: any,
}

class SliderDownload extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    const {items} = this.props;
    if (items && items.length === 1) {
      this.download(items[0].attribute.mat_file_dir);
    } else {
      this.setState({anchorEl: event.currentTarget})
    }
  }

  handleDownload = (url) => {
    this.download(url);
    this.setState({anchorEl: null})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }

  getCookieByName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
  }

  generatorLinkDownload(fileUrl) {
    const u = this.getCookieByName("u");
    const a = this.getCookieByName("a");
    return `${process.env.REACT_APP_DOWNLOAD_DOCUMENT_ENDPOINT}${fileUrl}?u=${u}&a=${a}`
  }

  download(url) {
    const element = document.createElement('a');
    element.setAttribute('href', this.generatorLinkDownload(url));
    element.setAttribute('target', '_blank');

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getFileName(attribute) {
    if (!attribute || !attribute.mat_file_dir) return attribute.mat_name;
    return attribute.mat_file_dir.substring(attribute.mat_file_dir.lastIndexOf('/') + 1);
  }

  render() {
    return (
      <>
        {
          (this.props.items != undefined && this.props.items.length > 0) ?
            (
              <>
                <Tooltip
                    placement="top"
                    title="資料ダウンロード">
                  <button className="btn-icon" onClick={this.handleClick}>
                    <img className="btn-slide-download" src="/download.svg" alt="download"/>
                  </button>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  {this.props.items.map((item, index) => (
                    <MenuItem
                      key={index}
                      component={'a'}
                      onClick={() => this.handleDownload(item.attribute.mat_file_dir)}>{this.getFileName(item.attribute)}</MenuItem>
                  ))}
                </Menu>
              </>
            ) : <span></span>
        }
      </>
    )
  };
}

export default SliderDownload;
